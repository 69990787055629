import { useApi } from '@tenant/composables'

export type TextTemplateState = {
    textTemplateLanguages: any[]
    textTemplateTypes: any[]
}

export default {
    state: (): TextTemplateState => ({
        textTemplateLanguages: [],
        textTemplateTypes: [],
    }),

    mutations: {
        setTextTemplateLanguages(state: TextTemplateState, items: []) {
            state.textTemplateLanguages = items
        },
        setTextTemplateTypes(state: TextTemplateState, items: []) {
            state.textTemplateTypes = items
        },
    },

    getters: {
        textTemplateLanguages(state: TextTemplateState) {
            return state.textTemplateLanguages
        },
        textTemplateTypes(state: TextTemplateState) {
            return state.textTemplateTypes
        },
    },

    actions: {
        fetchTextTemplateLanguages({
            commit,
        }: {
            commit: (...args: any[]) => void
        }) {
            const { execute } = useApi('/api/text-template-languages', 'GET')

            execute().then((items) => {
                commit('setTextTemplateLanguages', items)
            })
        },
        fetchTextTemplateTypes({
            commit,
        }: {
            commit: (...args: any[]) => void
        }) {
            const { execute } = useApi('/api/text-template-types', 'GET')

            execute().then((items) => {
                commit('setTextTemplateTypes', items)
            })
        },
    },
}
